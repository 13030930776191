<template>
  <div class="gatherPage">
    <div class="box_top">
      <Header class="page_hea" />
      <div class="top_tlite">
        <div>JEPaaS云平台公开招募产品合作伙伴</div>
        <div>用JEPaaS平台做开发，1.5倍价格回购你的产品！</div>
      </div>
    </div>
    <div class="box_o">
      <p>江湖召集令</p>
      <p>JEPaaS现面向全国发出召集令！</p>
      <p>只要你成为JEPaaS用户，只要你认为你的产品有价值，就可以申请加入江湖招募计划。</p>
      <p>审核通过的用户，将与JEPaaS签约：</p>
      <div
        class="buttonShen"
        @click="openqq"
      >
        马上咨询
      </div>
      <div class="we_con">
        <div class="we_title">
          我们的承诺
        </div>
        <div class="we_box">
          <div>1.用户使用JEPaaS来开发产品；</div>
          <div>2.JEPaaS承诺产品开发出来后，将以用户所购平台版本的1.5倍的价格回购产品；</div>
          <div>3.JEPaaS利用JEPaaS的线上线下所有渠道帮助产品推广和销售！</div>
        </div>
        <div class="we_bottom">
          也就是说，您的产品一旦通过审核，相当于倒给你钱用平台，而且JEPaaS还帮你卖产品！
        </div>
      </div>
      <div class="aims_box">
        <div class="we_title">
          我们的目标
        </div>
        <span>2019</span>
        <span>年，筛选出</span>
        <span>50</span>
        <span>个“江湖产品”</span>
      </div>
      <div class="tit">
        名额有限，马上行动
      </div>
      <div
        class="buttonShen"
        @click="openWin"
      >
        立即申请
      </div>
    </div>
    <div class="box_s">
      <div class="box_title">
        加入江湖招募计划有什么好处？
      </div>
      <div class="but_con">
        <div>
          <img
            src="../../../assets/imgs/gather/but1.png"
            alt=""
          >
          <div>平台免费用</div>
          <div>JEPaaS将用所购平台版本1.5倍的价格回购您的产品，不仅相当于免费使用平台，甚至是用平台还能赚到钱！</div>
        </div>
        <div>
          <img
            src="../../../assets/imgs/gather/but2.png"
            alt=""
          >
          <div>产品有出路</div>
          <div>与JEPaaS官方签约，开发出来的产品回购！相当于产品还没开发出来就已经有了买家！</div>
        </div>
        <div>
          <img
            src="../../../assets/imgs/gather/but3.png"
            alt=""
          >
          <div>营销有渠道</div>
          <div>产品交付后，JEPaaS将开放全渠道帮助你的产品销售，线上线下以及生态圈多种营销！</div>
        </div>
        <div>
          <img
            src="../../../assets/imgs/gather/but4.png"
            alt=""
          >
          <div>圈子助成长</div>
          <div>成为JEPaaS生态圈中一员，与全国各行业JEPaaS的用户们信息交流，资源互换，商机对接！</div>
        </div>
      </div>
    </div>
    <div class="box_th">
      <div class="box_title">
        轻松四步，快速加入！
      </div>
      <div class="box_con">
        <div class="num1 num">
          <div>提交申请</div>
          <div>等待工作人员联系</div>
        </div>
        <img
          class="sanjiao"
          src="../../../assets/imgs/gather/sanj.png"
          alt=""
        >
        <div class="num2 num">
          <div>审核通过</div>
          <div>确定合作方案 </div>
        </div>
        <img
          class="sanjiao"
          src="../../../assets/imgs/gather/sanj.png"
          alt=""
        >
        <div class="num3 num">
          <div>签订协议</div>
          <div>正式开启合作</div>
        </div>
        <img
          class="sanjiao"
          src="../../../assets/imgs/gather/sanj.png"
          alt=""
        >
        <div class="num4 num">
          <div>费用支付</div>
          <div>
            先购买平台产品
            交付后按合同结算费用
          </div>
        </div>
      </div>
      <div
        class="buttonShen"
        @click="openWin"
      >
        立即申请
      </div>
      <div class="but_titlt">
        更多相关问题可联系电话: 400-0999-235
      </div>
    </div>
    <Footer class="oot" />
    <el-dialog
      width="750px"
      height="510px"
      :visible.sync="dialogFormVisible"
    >
      <div class="open_con">
        <div class="open_title">
          JEPaaS江湖产品合作申请
        </div>
        <div>
          <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            label-position="right"
            label-width="150px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="公司/个人名称"
              prop="corporateName"
            >
              <el-input v-model="ruleForm.corporateName" />
            </el-form-item>
            <el-form-item
              label="联系电话"
              prop="telPhone"
            >
              <el-input v-model="ruleForm.telPhone" />
            </el-form-item>
            <el-form-item label=" 产品">
              <el-input v-model="ruleForm.describe" />
            </el-form-item>
          </el-form>
        </div>
        <div
          class="buttonShen"
          @click="submitForm('ruleForm')"
        >
          提交申请
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'GatherPage',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      dialogFormVisible: false,
      prohibit: true,
      ruleForm: {
        corporateName: '',
        telPhone: '',
        describe: '',
      },
      rules: {
        corporateName: [
          { required: true, message: '请输入您的公司或个人名称 ', trigger: 'blur' },
        ],
        telPhone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    openqq() {
      window.open('http://shang.qq.com/wpa/qunwpa?idkey=3d0bc04e6a1e43055b8e69fd8324c477a45fa1cba57cfcd03dc95ba61427c52c');
    },
    openWin() {
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    submitForm(formName) {
      const self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (self.prohibit) {
            self.prohibit = false;
            self.$ajax({
              url: '/je/product/crm/customer/addInfoForOpportunity',
              data: {
                type: 'JH',
                corporateName: self.ruleForm.corporateName,
                telPhone: self.ruleForm.telPhone,
                describe: self.ruleForm.describe,
              },
            }).then(() => {
              self.prohibit = true;
              this.$message.success('提交成功请静候佳音!');
              self.ruleForm.corporateName = '';
              self.ruleForm.telPhone = '';
              self.ruleForm.describe = '';
              this.dialogFormVisible = false;
            }).catch(() => {
              self.prohibit = true;
              this.$message.error('提交失败，请重试。');
            });
          }
        } else {
          this.$message.error('请将所有必填信息填写完整');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.gatherPage {
  .buttonShen {
    cursor: pointer;
    width: 240px;
    margin: 0px auto;
    height: 60px;
    background: rgba(255, 48, 65, 1);
    box-shadow: 2px 2px 10px 0px rgba(252, 44, 54, 0.4);
    border-radius: 30px;
    line-height: 60px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(254, 255, 255, 1);
    text-align: center;
    position: relative;
  }
  .buttonShen::after {
    content: "";
    width: 240px;
    height: 60px;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 30px;
    z-index: -1;
    transition: background 0.7s;
  }
  .buttonShen:hover:after {
    z-index: 2;
    background: rgba(51, 51, 51, 0.1);
  }
  .box_top {
    width: 100%;
    height: 600px;
    background-image: url(../../../assets/imgs/gather/top_bg.png);
    background-size: cover;
    .top_tlite {
      font-size: 40px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      div:nth-child(1) {
        padding-top: 225px;
        height: 35px;
        font-size: 36px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
      }
      div:nth-child(2) {
        padding-top: 36px;
        height: 59px;
        font-size: 60px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .box_o {
    text-align: center;
    padding-bottom: 136px;
    p {
      display: block;
      font-size: 24px;
      font-weight: 400;
      color: rgba(48, 51, 54, 1);
      line-height: 23px;
    }
    p:nth-child(1) {
      display: block;
      padding: 90px 0 30px 0;
      height: 39px;
      font-size: 40px;
      font-weight: bold;
      color: rgba(48, 51, 54, 1);
    }
    .we_con {
      margin: 0 auto;
      margin-top: 70px;
      padding: 0 20px;
      width: 1160px;
      height: 510px;
      background: rgba(240, 240, 240, 1);
      .we_title {
        padding: 80px 0 60px 0;
        height: 28px;
        font-size: 30px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
      }
      .we_box {
        display: flex;
        justify-content: space-around;
        div {
          display: flex;
          align-items: center;
          width: 260px;
          height: 181px;
          background: rgba(255, 48, 65, 1);
          box-shadow: 0px 5px 15px 0px rgba(255, 95, 72, 0.4);
          border-radius: 10px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          line-height: 27px;
          padding: 0 55px;
        }
      }
      .we_bottom {
        margin-top: 60px;
        height: 18px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(48, 51, 54, 1);
        line-height: 24px;
      }
    }
    .aims_box {
      margin: 0 auto;
      margin-top: 30px;
      width: 1200px;
      height: 300px;
      background: rgba(240, 240, 240, 1);
      .we_title {
        padding: 80px 0 60px 0;
        height: 28px;
        font-size: 30px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
      }
      span {
        height: 35px;
        font-size: 24px;
        font-weight: bold;
        color: #303336;
        line-height: 24px;
      }
      span:nth-child(2),
      span:nth-child(4) {
        color: rgba(255, 48, 65, 1);
        font-size: 40px;
      }
    }
    .tit {
      height: 18px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(48, 51, 54, 1);
      line-height: 24px;
      padding: 60px 0 25px 0;
    }
  }
  .box_s {
    width: 1920px;
    height: 656px;
    background: linear-gradient(
      49deg,
      rgba(238, 47, 62, 0.95) 0%,
      rgba(255, 94, 72, 0.98) 100%
    );
    .box_title {
      height: 38px;
      padding: 100px 0 80px 0;
      font-size: 40px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
    }
    .but_con {
      display: flex;
      justify-content: center;
      & > div {
        width: 243px;
        margin: 0 14px;
        padding: 0 18px;
        height: 330px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        img {
          margin-top: 45px;
          width: 80px;
          height: 80px;
        }
        & > div:nth-child(2) {
          padding: 30px 0;
          height: 24px;
          font-size: 24px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
          line-height: 24px;
        }
        & > div:nth-child(3) {
          text-align: left;
          height: 64px;
          font-size: 16px;
          font-weight: 400;
          color: rgba(153, 153, 153, 1);
          line-height: 24px;
        }
      }
    }
  }
  .box_th {
    .box_title {
      padding: 132px 0 100px 0;
      height: 39px;
      font-size: 40px;
      font-weight: bold;
      color: rgba(0, 0, 0, 1);
    }
    .box_con {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 0 auto;
      margin-top: 30px;
      margin-bottom: 135px;
      width: 1200px;
      .sanjiao {
        width: 20px;
        height: 28px;
      }
      .num1 {
        width: 240px;
        height: 200px;
        background-image: url(../../../assets/imgs/gather/num1.png);
        background-size: cover;
      }
      .num2 {
        width: 240px;
        height: 200px;
        background-image: url(../../../assets/imgs/gather/num2.png);
        background-size: cover;
      }
      .num3 {
        width: 240px;
        height: 200px;
        background-image: url(../../../assets/imgs/gather/num3.png);
        background-size: cover;
      }
      .num4 {
        width: 240px;
        height: 200px;
        background-image: url(../../../assets/imgs/gather/num4.png);
        background-size: cover;
      }
      .num {
        div:nth-child(1) {
          height: 38px;
          padding: 76px 0 43px 0;
          font-size: 40px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
        }
        div:nth-child(2) {
          height: 60px;
          font-size: 24px;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          line-height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .but_titlt {
      padding: 50px 0 130px 0;
      height: 17px;
      font-size: 16px;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 27px;
    }
  }
  .open_con {
    height: 470px;
    .open_title {
      padding: 20px 0 60px 0;
      font-size: 22px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }
    .demo-ruleForm {
      padding: 0 50px 40px 50px;
    }
  }
}
</style>
